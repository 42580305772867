<template>
  <div class="app">
    <img alt="logo" class="logo" src="@/assets/logo.png" />

    <div class="card">
      <template v-if="loginType === 'normal'">
        <img class="icon" src="@/assets/toggle-qrcode.png" alt="图标" @click="toggle('qrcode')" />
        <div class="title">统一身份认证登陆</div>
        <LoginForm @toggle="toggle" :redirect="redirect" />
      </template>
      <template v-else-if="loginType === 'qrcode'">
        <img class="icon" src="@/assets/toggle-computer.png" alt="图标" @click="toggle('normal')" />
        <div class="title">APP 扫码登录</div>
        <QrcodeLogin :redirect="redirect" />
      </template>
      <template v-else-if="loginType === 'forgot-password'">
        <div class="title">找回密码</div>
        <ForgotPassword @toggle="toggle" />
      </template>
      <template v-else>
        <div class="title">找回密码</div>
        <FindPassword @toggle="toggle" />
      </template>

      <div class="client">
        <span>下载客户端：</span>
        <a href="https://portal.njszy.com/desktop/%E7%93%B4%E4%BA%91%E6%95%B0%E5%AD%97%E5%8C%96%E4%BA%91%E5%B9%B3%E5%8F%B0%20Setup%201.0.1.exe"
          target="_blank">桌面版</a>
        <a style="padding-left: 8px" href="http://app.njszy.com/download-app.html" target="_blank">同仁街31号APP</a>

      </div>
    </div>

    <div class="footer">
      <PageFooter color="#fff" />
    </div>
  </div>
</template>

<script>
import LoginForm from "./login-form";
import QrcodeLogin from "./qrcode-login";
import ForgotPassword from "./forgot-password";
import FindPassword from "./find-password";
import PageFooter from "@/components/page-footer";

export default {
  components: {
    LoginForm,
    QrcodeLogin,
    ForgotPassword,
    FindPassword,
    PageFooter,
  },
  data() {
    return {
      loginType: "normal", // 登录类型：普通、二维码、忘记密码、找回密码
      redirect: "",
    };
  },

  mounted() {
    window.localStorage.clear();
    const { query } = this.$route;
    const { redirect } = query || {};
    this.redirect = redirect;
  },

  methods: {
    toggle(loginType) {
      this.loginType = loginType;
    },
  },
};
</script>

<style lang="less" scoped>
.app {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url(../../assets/bg.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  .logo {
    margin-bottom: 75px;
    height: 100px;
  }

  .card {
    background-color: rgba(255, 255, 255, 0.24);
    width: 430px;
    height: 400px;
    padding: 40px;
    box-sizing: border-box;
    border-radius: 20px;
    position: relative;
  }

  .icon {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 75px;
    cursor: pointer;
  }

  .title {
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 11px;
    color: #fff;
    border-bottom: 2px solid #fff;
    display: inline-block;
    margin-bottom: 40px;
  }

  .footer {
    position: fixed;
    bottom: 8px;
  }

  .client {
    margin-top: 24px;
    color: #fff;

    a {
      color: #fff;
      font-weight: bold;
    }
  }
}
</style>
