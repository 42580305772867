<template>
  <div>
    <canvas id="canvas"></canvas>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
const QRCode = require("qrcode");
import { qrcodeLogin } from "@/api/login";
import { mapMutations } from "vuex";
export default {
  props: {
    redirect: {
      type: String,
      default: ""
    }
  },

  data() {
    return {
      uuid: ""
    };
  },
  mounted() {
    this.getQRCode();
  },
  methods: {
    ...mapMutations("auth", ["setUser"]),

    getQRCode() {
      this.uuid = uuidv4();

      var canvas = document.getElementById("canvas");
      const that = this;

      QRCode.toCanvas(
        canvas,
        this.uuid,
        {
          margin: 2,
          width: 200
        },
        function(error) {
          if (error) {
            console.error(error);
          } else {
            console.log("qrcode build success!");
            that.setTimer();
          }
        }
      );
    },

    setTimer() {
      const timer = setInterval(() => {
        qrcodeLogin({
          qrId: this.uuid
        }).then(res => {
          console.log("res", res);

          if (res && res.token && res.user) {
            window.localStorage.setItem("token", res.token);
            this.setUser(res.user);
            if (this.redirect) {
              window.open("/#" + this.redirect, "_self");
            } else {
              window.open("/", "_self");
            }
          }
        });
      }, 3000);
      this.$once("hook:beforeDestroy", () => {
        clearInterval(timer);
      });
    }
  }
};
</script>

<style lang="less" scoped>
#canvas {
  width: 200px;
  height: 200px;
  display: flex;
  margin: 0 auto;
}
</style>
