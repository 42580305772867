<template>
  <a-form :form="form" @submit="handleSubmit">
    <a-form-item>
      <a-input
        v-decorator="[
          'name',
          { rules: [{ required: true, message: '请输入！' }] },
        ]"
        size="large"
        placeholder="用户名或手机号"
      >
        <a-icon slot="prefix" type="user" />
      </a-input>
    </a-form-item>
    <a-form-item>
      <a-input
        v-decorator="[
          'password',
          { rules: [{ required: true, message: '请输入！' }] },
        ]"
        type="password"
        size="large"
        placeholder="密码"
      >
        <a-icon slot="prefix" type="lock" />
      </a-input>
    </a-form-item>
    <a-form-item v-if="imagePath">
      <a-row :gutter="8">
        <a-col span="18">
          <a-input
            v-decorator="[
              'code',
              { rules: [{ required: true, message: '请输入！' }] },
            ]"
            size="large"
            placeholder="请输入验证码"
          >
            <a-icon slot="prefix" type="key" />
          </a-input>
        </a-col>
        <a-col span="6" class="code-image">
          <img :src="imagePath" alt="验证码" />
        </a-col>
      </a-row>
    </a-form-item>

    <div>
      <div class="forgot" @click.prevent="forgetPassword">忘记密码？</div>
    </div>

    <a-button
      :loading="logging"
      style="width: 100%; margin-top: 24px"
      size="large"
      type="primary"
      html-type="submit"
    >登录</a-button>
  </a-form>
</template>

<script>
import { login, fetchCode } from "@/api/login";
import { mapMutations } from "vuex";

export default {
  props: {
    redirect: {
      type: String,
      default: ""
    }
  },

  data() {
    return {
      logging: false, // 是否正在登录
      form: this.$form.createForm(this),
      imagePath: null
    };
  },

  methods: {
    ...mapMutations("auth", ["setUser"]),

    getCode() {
      fetchCode().then(response => {
        response.arrayBuffer().then(res => {
          const blob = new Blob([res], { type: "image/*" });

          this.imagePath = window.URL.createObjectURL(blob);
        });
      });
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.logging = true;

          const params = {
            password: this.$encrypt.encrypt(values.password)
          };

          if (/^1[0-9]{10}$/.test(values.name)) {
            params.mobile = values.name;
          } else {
            params.name = values.name;
          }

          login(params)
            .then(this.afterLogin)
            .catch(() => {
              this.logging = false;
            });
        }
      });
    },

    afterLogin(res) {
      if (res && res.token && res.user) {
        window.localStorage.setItem("token", res.token);
        this.setUser(res.user);
        this.logging = false;
        if (this.redirect) {
          window.open("/#" + this.redirect, "_self");
        } else {
          window.open("/", "_self");
        }
      }
    },

    forgetPassword() {
      this.$emit("toggle", "forgot-password");
    }
  }
};
</script>

<style lang="less" scoped>
.code-image {
  img {
    width: 100%;
    height: 40px;
    display: flex;
  }
}
.register {
  float: left;
  color: #fff;
  cursor: pointer;
}
.forgot {
  float: right;
  color: #fff;
  cursor: pointer;
}
</style>