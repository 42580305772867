import request from '../request'


export function login(data) {
    return request({
        url: '/auth-service/auth/login',
        method: 'POST',
        data,
    })
}

export function fetchCode({ mobile }) {
    return request({
        url: '/auth-service/auth/verifycode/send?mobile=' + mobile,
    })
}

export function validateCode({ mobile, code }) {
    return request({
        url: `/auth-service/auth/verifycode/validate?code=${code}&mobile=${mobile}`
    })
}

export function updatePassword({ password, uuid }) {
    return request({
        url: '/auth-service/auth/verifycode/update',
        data: { password, uuid },
        method: "post"
    })
}

// 使用二维码登录
export function qrcodeLogin(data) {
    return request({
        url: '/auth-service/auth/qrlogin',
        method: 'post',
        data,
    })
}