var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'name',
        { rules: [{ required: true, message: '请输入！' }] },
      ]),expression:"[\n        'name',\n        { rules: [{ required: true, message: '请输入！' }] },\n      ]"}],attrs:{"size":"large","placeholder":"用户名或手机号"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'password',
        { rules: [{ required: true, message: '请输入！' }] },
      ]),expression:"[\n        'password',\n        { rules: [{ required: true, message: '请输入！' }] },\n      ]"}],attrs:{"type":"password","size":"large","placeholder":"密码"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),(_vm.imagePath)?_c('a-form-item',[_c('a-row',{attrs:{"gutter":8}},[_c('a-col',{attrs:{"span":"18"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'code',
            { rules: [{ required: true, message: '请输入！' }] },
          ]),expression:"[\n            'code',\n            { rules: [{ required: true, message: '请输入！' }] },\n          ]"}],attrs:{"size":"large","placeholder":"请输入验证码"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"key"},slot:"prefix"})],1)],1),_c('a-col',{staticClass:"code-image",attrs:{"span":"6"}},[_c('img',{attrs:{"src":_vm.imagePath,"alt":"验证码"}})])],1)],1):_vm._e(),_c('div',[_c('div',{staticClass:"forgot",on:{"click":function($event){$event.preventDefault();return _vm.forgetPassword.apply(null, arguments)}}},[_vm._v("忘记密码？")])]),_c('a-button',{staticStyle:{"width":"100%","margin-top":"24px"},attrs:{"loading":_vm.logging,"size":"large","type":"primary","html-type":"submit"}},[_vm._v("登录")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }